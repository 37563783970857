// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseCatalogue } from "../../utilities/src/helpers/utils";
import { Navigation } from "../../utilities/src/models/Navigation";
import StorageProvider from "../../../framework/src/StorageProvider";
import { Brand } from "../../catalogue/src/CataloguesController.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export const configJSON = require("./config");

export interface Props {
  navigation: Navigation;
  identifier: string;
  classes: Record<string, string>;
}

interface S {
  service: Service | null;
  selectedImageIndex: number;
  renameService: string;
}

interface SS {
  identifier: string;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getCatalogueApiCallId: string | undefined;
  getBrandsApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      service: null,
      selectedImageIndex: 0,
      renameService:"" 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCatalgoue();
    this.getBrands();
  }

  receive = async (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        responseJson.data.attributes.status == false &&
          this.props.navigation.navigate("Services");
        this.handleGetCatalgoueResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleBrandName(responseJson);
      }
    }
  };

  handleGetCatalgoueResponse = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const response = responseJson.data;

    this.setState({
      service: parseCatalogue(response)
    });
  };

  getCatalgoue = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    const catalogueId = this.props.navigation.getParam("id");

    this.getCatalogueApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${configJSON.getCataloguesEndPoint}/${catalogueId}`,
      header
    });
  };

  previewImage = (index: number) => {
    this.setState({
      selectedImageIndex: index
    });
  };

  homeNavigation = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage),"Home");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(navigation);
  };

  servicesNavigation = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage),"Services");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(navigation);
  };

  appoinmentNavigation = (catalogueId: string) => {
    mixpanel.track("webcustomer_services_select_item", {itemId: catalogueId});
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage),"Appointments");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(navigation);
    StorageProvider.set("catalogueId", catalogueId);
    StorageProvider.set("navigationType", "service");
  };

  getBrands = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getBrandsApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandsEndPoint,
      header,
    });
  };

  handleBrandName = (responseJson: Brand) => {
    this.setState({renameService : responseJson.data.attributes.header.navigation_item_1});
  };
}
// Customizable Area End
