// Customizable Area Start
import React, { Suspense } from "react";
import StaffListingPageController, { Props } from "./StaffListingPageController";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    CircularProgress,
    List,
    ListItem,
    Theme,
    Typography,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { NavigateNext as NavigateNextIcon, Close as CloseIcon } from "@material-ui/icons";
const configJSON = require("./config");
import { IconNotFound } from "../../catalogue/src/assets";
import { StaffDummyImage } from "./assets";
import { Link } from "react-router-dom";
import Currency from "../../utilities/src/components/Currency.web";
import Skeleton from "@material-ui/lab/Skeleton";
const ImageCard = React.lazy(() => import('../../utilities/src/components/ImageCard.web'));
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End

export class Advancesearch extends StaffListingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            staffList,
            loading,
            searchQuery,
            searchListService
        } = this.state;

        const style = this.props.classes;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box className={style.breadcrumbsWrapper}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ color: "black" }} />}>
                        <Box className={style.breadcrumbLink} onClick={this.goBack}>
                            <Typography>{configJSON.textHome}</Typography>
                        </Box>
                        <Typography className={style.breadcrumbText}>
                            {configJSON.searchResultText}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                
                
            
                <Box className={style.container}>
                    <Box className={style.resultsWrapper}>

                        {searchQuery && (
                            <Chip
                                label={searchQuery}
                                variant="outlined"
                                className={style.chip}
                                onDelete={this.deleteChip}
                                deleteIcon={<CloseIcon className={style.deleteChipIcon} />}
                            />
                        )}

                        {loading && (
                            <Box className={style.skeleton}>
                                <Box>
                                <Skeleton variant="rect" className={style.skeletonContainer} />
                                <Skeleton width={"100%"} />
                                <Skeleton width={"90%"} />
                                </Box>
                               
                                <Box>
                                <Skeleton variant="rect" className={style.skeletonContainer} />
                                <Skeleton width={"100%"} />
                                <Skeleton width={"90%"} />
                                </Box>

                                <Box>
                                <Skeleton variant="rect" className={style.skeletonContainer} />
                                <Skeleton width={"100%"} />
                                <Skeleton width={"90%"} />
                                </Box>

                                <Box>
                                <Skeleton variant="rect" className={style.skeletonContainer} />
                                <Skeleton width={"100%"} />
                                <Skeleton width={"90%"} />
                                </Box>
                            </Box>
                       
                        )}

                        {!loading && searchListService.length > 0 && (
                            <>
                                <Box className={style.serviceAdvanceSearchHeader}>
                                    {configJSON.textService}
                                </Box>
                                <Box className={style.borderLine}></Box>
                                <Box className={style.listWrapper}>
                                    <List className={style.list}>
                                        {searchListService.map((serviceList, index) => {
                                            const serviceListData = serviceList.attributes;
                                            return (
                                                <ListItem key={index} className={style.item}>
                                                    <Link
                                                        className={style.link}
                                                        to={`/Service/${serviceList.id}`}
                                                        onClick={() => this.navigateStaffDetailPage(serviceList.id)}
                                                    >
                                                        <Card className={style.card}>
                                                            <Suspense fallback={<CircularProgress />}>
                                                                <ImageCard
                                                                    imageClassName={style.image}
                                                                    image={serviceListData.images[0]?.small_url ? serviceListData.images[0]?.small_url : (serviceListData.images[0]?.url ? serviceListData.images[0]?.url : "" )}
                                                                    alt="image loading"
                                                                />
                                                            </Suspense>
                                                            <CardContent className={style.content}>
                                                                <Typography className={style.name}>
                                                                    {serviceListData.title ? serviceListData.title : ""}
                                                                </Typography>
                                                                <Typography className={style.brief}>
                                                                    <Currency text={`${serviceListData.discount?.toFixed(2)} `} disountPrice={`${serviceListData.actual_price?.toFixed(2)}`} time={""} hasDiscount={serviceListData.discount_option} /> | {serviceList.attributes.duration}{configJSON.textMins}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Link>
                                                    <Button disableRipple onClick={() => this.handleBookNow(serviceList.id)} className={style.bookNowButton}>{configJSON.textBookNow}</Button>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Box>
                            </>
                        )}
                        {
                            !loading && staffList.length > 0 && searchListService.length > 0 &&
                            <Box className={style.subContainer}>
                            </Box>
                        }
                        {!loading && staffList.length > 0 && (
                            <>
                                <Box className={style.serviceAdvanceSearchHeader}>
                                    {configJSON.textServiceProvider}
                                </Box>
                                <Box className={style.borderLine}></Box>
                                <Box className={style.listWrapper}>
                                    <List className={style.list}>
                                        {staffList.map((staffList, index) => (
                                            <ListItem key={index} className={style.item}>
                                                <Link
                                                    className={style.link}
                                                    to={`/StaffDetails/${staffList.id}`}
                                                    onClick={() => this.navigateStaffDetailPage(staffList.id)}
                                                >
                                                    <Card className={style.card}>
                                                        <Suspense fallback={<CircularProgress />}>
                                                            <ImageCard
                                                                imageClassName={style.image}
                                                                image={staffList?.attributes?.images[0]?.small_url ? staffList?.attributes?.images[0]?.small_url : (staffList?.attributes?.images[0]?.url ? staffList?.attributes?.images[0]?.url : StaffDummyImage )}
                                                                alt="image loading"
                                                            />
                                                        </Suspense>
                                                        <CardContent className={style.content}>
                                                            <Typography className={style.name}>
                                                                {staffList.attributes.full_name ? staffList.attributes.full_name : ""}
                                                            </Typography>
                                                            <Typography className={style.brief}>
                                                                {staffList.attributes.designation ? staffList.attributes.designation : ""}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                                <Button disableRipple onClick={() => this.handleBookNow(staffList.id)} className={style.bookNowButton}>{configJSON.textBookNow}</Button>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </>
                        )}

                        {!loading && staffList.length === 0 && searchListService.length === 0 && (
                            <Card className={style.noServicesCard}>
                                <CardMedia
                                    component="img"
                                    image={IconNotFound}
                                    className={style.notFoundImage}
                                />
                                <Typography className={style.noResultsFound}>
                                    {configJSON.textNoResultsFound}
                                </Typography>
                                <Typography className={style.pleaseRefineYourSearch}>
                                    {configJSON.textNoResultHint}
                                </Typography>
                            </Card>
                        )}
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Styles = (theme: Theme) =>
    createStyles({
        breadcrumbsWrapper: {
            margin: theme.spacing(5, 10, 1),
            [theme.breakpoints.down(1280)]: {
                margin: theme.spacing(2, 10.3, 1),
                display: "flex",
                justifyContent: "space-between"
            },
            [theme.breakpoints.down(960)]: {
                margin: theme.spacing(2, 2.25, 1),
                display: "flex",
                justifyContent: "space-between"
            },
        },
        breadcrumbLink: {
            color: "#3FC1CB",
            textDecoration: "none",
            cursor: "pointer"
        },
        breadcrumbText: {
            color: "#000000"
        },
        container: {
            display: "flex",
            gap: theme.spacing(3.5),
            margin: theme.spacing(0, 9),
            [theme.breakpoints.down(1280)]: {
                margin: theme.spacing(0)
            }
        },
        noServicesCard: {
            flex: "1 1 75%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: theme.spacing(2),
            padding: theme.spacing(12.5),
            height: "394px",
            marginBottom: '10px',
            [theme.breakpoints.down(480)]: {
                padding: theme.spacing(7.5),
            }
        },
        loadingResults: {
            display: "flex",
            alignItems: "center"
        },
        loadingResultsText: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
            whiteSpace: "pre"
        },
        notFoundImage: {
            width: "103px"
        },
        noResultsFound: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
            marginTop: theme.spacing(5)
        },
        pleaseRefineYourSearch: {
            fontSize: "14px",
            lineHeight: "24px",
            color: "#83889E",
            marginTop: theme.spacing(1)
        },
        resultsWrapper: {
            display: "flex",
            flexDirection: "column",
            flex: "1 1 75%",
            margin: '0px 10px',
            [theme.breakpoints.down(1280)]: {
                margin: '0px 83px'
            },
            [theme.breakpoints.down(960)]: {
                margin: '0px 18px'
            },
            [theme.breakpoints.down(480)]: {
                margin: '0px 18px'
            },
        },
        chip: {
            alignSelf: "flex-start",
            minHeight: "32px",
            marginTop: theme.spacing(2),
            backgroundColor: "#FFFFFF",
            border: "1px solid #D0D2DA",
            fontSize: "14px",
            lineHeight: "24px",
            marginBottom: "20px",
            [theme.breakpoints.down(1280)]: {
                marginTop: theme.spacing(0),
                marginLeft: theme.spacing(0)
            },
            [theme.breakpoints.down(480)]: {
                marginLeft: theme.spacing(0)
            }
        },
        deleteChipIcon: {
            width: "10px",
            height: "10px",
            color: "#D0D2DA",
            marginRight: "10px !important",
            marginLeft: theme.spacing(0)
        },
        listWrapper: {
            display: "flex",
            flex: "1 1 75%",
            justifyContent: "space-between"
        },
        list: {
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            width: "100%",
            [theme.breakpoints.up(1440)]: {
                maxWidth: "1330px",
            }
        },
        item: {
            width: "calc(100% / 4)",
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("xs")]: {
                width: "calc(100% / 2)",
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
            [theme.breakpoints.down(1280)]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            }
        },
        link: {
            textDecoration: "none",
            width: "100%",
            display: "flex"
        },
        card: {
            display: "flex",
            flexDirection: "column",
            width: "424px",
            height: "352px",
            flex: "1",
            boxShadow: "0 0",
            backgroundColor: "inherit",
            [theme.breakpoints.down(1280)]: {
                width: "170px",
                height: "311px",
            },
            borderRadius: "0px"
        },
        image: {
            height: "100%",
            overflow: "hidden"
        },
        content: {
            flex: "1 1 auto",
            padding: "16px 0"
        },
        name: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            [theme.breakpoints.down(1280)]: {
                fontSize: "14px",
                lineHeight: "20px",
            }
        },
        brief: {
            fontSize: "14px",
            lineHeight: "24px",
            color: "#000000",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            [theme.breakpoints.down(1280)]: {
                fontSize: "12px",
                lineHeight: "20px",
            }
        },
        bookNowButton: {
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            backgroundColor: "#3FC1CB",
            color: "#FFFFFF",
            borderRadius: "5px",
            padding: "6px 60px",
            cursor: "pointer",
            [theme.breakpoints.down("sm")]: {
                flex: 1,
                padding: "6px 10px"
            },
            "&:hover": {
                backgroundColor: "#3FC1CB",
            }
        },
        serviceAdvanceSearchHeader: {
            fontFamily: "rubik",
            fontWeight: 500,
            fontSize: "32px",
            color: "#364F6B",
            letterSpacing: "2px",
            [theme.breakpoints.down(480)]: {
                fontSize: "20px",
            }
        },
        borderLine: {
            width: "160px",
            borderBottom: "1px solid #3FC1CB",
            borderBottomWidth: "3px",
            marginBottom: "12px",
            [theme.breakpoints.down(480)]: {
                width: "72px",
                marginBottom: "5px",
            }
        },
        subContainer: {
            fontFamily: "Rubik",
            paddingTop: "30px",
            marginTop: "30px",
            marginBottom: "20px",
            borderTop: "1px solid #BFBFBF",
            [theme.breakpoints.down(480)]: {
                paddingTop: "10px",
                marginTop: "10px",
                marginBottom: "10px",
            }
        },
        skeleton:{
            display:"flex",
            flexWrap: "wrap",
            flex: "1 1 75%",
            justifyContent: "start",
            gap:"10px"
          },
          skeletonContainer: {
            width: "320px",
            height: "320px",
            [theme.breakpoints.down(1250)]: {
              width: "330px",
              height: "320px",
            },
            [theme.breakpoints.down(1024)]: {
              width: "300px",
              height: "250px",
            },
            [theme.breakpoints.down(800)]: {
              width: "350px",
              height: "200px",
            },
            [theme.breakpoints.down(600)]: {
              width: "200px",
              height: "200px",
            },
          },
    });
export default withStyles(Styles, { withTheme: true })(Advancesearch);
// Customizable Area End