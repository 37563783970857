import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ImageListType, SelectedImage } from "./types";

import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { utc } from "moment";
import { PaginationType } from "../../ordermanagement/src/types";
import  {Brand}  from "../../catalogue/src/CataloguesController.web";
const navigation = require("react-navigation");
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation,
  identifier: string;
  classes: Record<string,string>;
  selectedImage?: SelectedImage;
  selectedImageId?: string;
  onBackButtonClick?: () => void;
  success?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAddImageModalVisible: boolean;
  isViewImageModalVisible: boolean;
  isVisibleDeleteCheckbox: boolean;
  isShareModalVisible: boolean;
  imageData: ImageListType[];
  selectedImage: SelectedImage;
  viewSelectedImage: SelectedImage;
  selectedImageId: string | undefined;
  addImageError: boolean;
  photoLibraryId: string[];
  inputAccountId: string;
  inputAccountIdError: boolean;
  loading: boolean;
  openDialog: boolean;
  selectedFile: File | null;
  selectedRow: string[];
  errorMessage: string;
  showSuccessMessage: boolean;
  showEditPage: boolean;
  isImageUploading: boolean;
  isSaving: boolean;
  pagination?: PaginationType;
  renameGallery: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  identifier: string;
  // Customizable Area End
}

export default class PhotoLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPhotoLibraryApiCallId = "";
  addImageToPhotoLibraryApiCallId = "";
  updateImageToPhotoLibraryApiCallId = "";
  deletePhotoLibraryApiCallId = "";
  getBrandsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isAddImageModalVisible: false,
      isViewImageModalVisible: false,
      isVisibleDeleteCheckbox: false,
      isShareModalVisible: false,
      selectedImage: { uri: "" },
      viewSelectedImage: { uri: "" },
      selectedImageId: "",
      addImageError: false,
      imageData: [],
      photoLibraryId: [],
      inputAccountId: "",
      inputAccountIdError: false,
      loading: true,
      selectedFile: null,
      openDialog: false,
      selectedRow: [],
      errorMessage: "",
      showSuccessMessage: false,
      showEditPage: false,
      isImageUploading: false,
      isSaving: false,
      renameGallery: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token });
        this.getPhotoLibrary(token);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPhotoLibraryApiCallId != null &&
      this.getPhotoLibraryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson && responseJson.data) {
        const photoData = responseJson.data;
        let newPhotoArr: ImageListType[] = [];
        photoData.forEach((imgData: ImageListType) => {
          const { id, attributes } = imgData;
          if (attributes) {
            let newImage = {
              id: id,
              isSelected: false,
              file_name: "abc",
              file_data: null,
              file_url: location.pathname.includes("/Gallery/List") ? attributes.gallery_image.thumbnail_url : attributes.gallery_image.large_url,
              created_at: attributes.gallery_image.created_at
            };
            newPhotoArr.push(newImage);
          }
        });
        this.setState({
          imageData: newPhotoArr,
          photoLibraryId: responseJson.data.id,
          pagination: responseJson.metadata?.meta?.pagination,
          loading: false
        });

      } else if (responseJson && responseJson.errors) {
        this.showAlert("Alert", "Something went wrong.");
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addImageToPhotoLibraryApiCallId != null &&
      this.addImageToPhotoLibraryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ isImageUploading: false, isSaving: false })
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson && responseJson.data) {
        this.setState({
          selectedImage: { uri: "" },
          addImageError: true,
          selectedFile: null,
        });
        this.props.navigation.goBack()
        this.getPhotoLibrary(this.state.token);
      } else if (responseJson && responseJson.errors) {
        this.showAlert("Alert", "Something went wrong.");
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateImageToPhotoLibraryApiCallId != null &&
      this.updateImageToPhotoLibraryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ isImageUploading: false, isSaving: false })
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data) {
        this.setState({
          selectedImage: { uri: "" },
          selectedImageId: "",
          selectedFile: null,
          showEditPage: false,
        });
      } else  if (responseJson?.errors) {
        this.showAlert("Alert", "Something went wrong.");
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deletePhotoLibraryApiCallId != null &&
      this.deletePhotoLibraryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.message) {
        this.setState({ showSuccessMessage: true },() => {
          this.setState({loading: false})
          this.getPhotoLibrary(this.state.token);
        })
        
        setTimeout(() => {
          this.setState({ showSuccessMessage: false });
        }, 4000);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.handleBrandName(responseJson)
      } 
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.getPhotoLibrary(this.state.token);
    this.getBrands();
    // Customizable Area End
  }

  // Customizable Area Start
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const toMessage = new Message(getName(MessageEnum.NavigationMessage));
    toMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    toMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(toMessage.messageId, toMessage);
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  handleDeleteClick = (idNumber: string[]) => {
    this.setState((prevState) => ({
      openDialog: !prevState.openDialog,
      selectedRow: idNumber,
    }));
  };

  showDeleteButton = (idNumber: string) => {
    this.setState((prevState) => {
      const selectedRow = [...prevState.selectedRow];
      const position = selectedRow.indexOf(idNumber);

      if (position !== -1) {
        selectedRow.splice(position, 1);
      } else {
        selectedRow.push(idNumber);
      }

      return { selectedRow };
    });
  };

  handleSelectAllFields = () => {
    this.setState((prevState) => {
      const allRowsSelected = prevState.selectedRow.length === prevState.imageData.length;
      const updatedSelectedRow = allRowsSelected ? [] : prevState.imageData.map((item: { id: string }) => item.id);

      return {
        selectedRow: prevState.selectedRow.length > 0 ? [] : updatedSelectedRow,
      };
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      selectedRow: [],
    });
  };


  handleConfirmDelete = () => {
    this.setState({loading: true})
    const idsToDelete = this.state.selectedRow;
    if (idsToDelete) {
      const newImageData = this.state.imageData.filter(
        (item: { id: string }) => !idsToDelete.includes(item.id)
      );
      this.setState({
        selectedRow: [],
        openDialog: false,
        imageData: newImageData,
      });
      this.deletePhotoLibrary(idsToDelete);
    }
  };

  getPhotoLibrary = async (token: string, page = 1) => {
    const requestMessage = await generateRequestMessage(
      configJSON.photoLibraryApiEndpoint  + "?page=" + page + "&per_page=12",
      configJSON.getApiMethod
    )
    this.getPhotoLibraryApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deletePhotoLibrary = async (queryIds: string[]) => {
    const idsQueryParam = queryIds?.map(queryId => `[ids][]=${parseInt(queryId)}`).join("&");
    const requestMessage = await generateRequestMessage(
      configJSON.photoDeleteApiEndpoint + `?${idsQueryParam}`,
      configJSON.deleteApiMethod
    )
    this.deletePhotoLibraryApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  uploadImage = async () => {
    this.setState({ isSaving: true })
    const selectedFile = this.state.selectedFile
    if (selectedFile) {
      const formData = new FormData();
      formData.append(
        "[data][gallery_image]",
        selectedFile,
      );

      const requestMessage = await generateRequestMessage(
        configJSON.photoLibraryApiEndpoint,
        configJSON.postApiMethod,
        {
          "content-type": undefined
        }
      )
      this.addImageToPhotoLibraryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({ isImageUploading: true })
    }
  };

  updateImage = async (imageId: string) => {
    this.setState({ isSaving: true });
    const selectedFile = this.state.selectedFile
    if (selectedFile && imageId) {
      const formData = new FormData();
      formData.append("[id]", imageId);

      formData.append("[data][gallery_image]", selectedFile);

      const requestMessage = await generateRequestMessage(
        configJSON.photoLibraryUpdateApiEndpoint,
        configJSON.updateApiMethod, {
        "content-type": undefined
      }
      )
      this.updateImageToPhotoLibraryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({ isImageUploading: true })
    }
  };

  discardImage = () => {
    this.setState({
      selectedImage: { uri: "" },
      selectedImageId: "",
      selectedFile: null,
      showEditPage: false
    });
  };

  checkImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const reader = new FileReader();
      const selectedFile = event.target.files[0];

      if (selectedFile.size > 20 * 1024 * 1024) {
        this.setState({ errorMessage: "Image size should not be greater than 20MB" });
        return;
      }

      reader.onload = (event) => {
        const allowedFormats = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'];
        const fileType = selectedFile.type;
        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
        if (allowedFormats.includes(fileType) || (fileExtension && allowedFormats.includes(`image/${fileExtension}`))) {
          this.setState({
            selectedImage: { uri: event.target?.result?.toString() || '' },
            addImageError: false,
            selectedFile: selectedFile,
            errorMessage: '',
          });
        } else {
          this.setState({
            errorMessage: 'Image should be a JPEG, PNG, GIF, or JPG type',
          });
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  showGalleryImage = (item: ImageListType | null) => {
    if (item) {
      this.setState((prevState) => ({
        showEditPage: !prevState.showEditPage,
        selectedImage: { uri: item.file_url },
        selectedImageId: item.id,
      }));
    } else {
      this.getPhotoLibrary(this.state.token);
      this.setState({
        showEditPage: false,
        selectedImage: { uri: '' },
        selectedImageId: '',
      });
    };
  };

  btnBackProps = () => {
    this.props.navigation.goBack()
  }

  formatDate = (inputDate: string): string => {
    return utc(inputDate).format("LLL")
  }

  handlePageChange = (page: number) => {
    this.getPhotoLibrary(this.state.token,page);
    window.scrollTo({top:0, behavior:"smooth"})
  }

  handleNavigation = (endPoints: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), endPoints);
    navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(navigation);
  }

  handleAddImage = () => {
    this.handleNavigation("Gallery/ImageUpload");
  }

  handleNavigateGalleryListsView = () => {
    this.handleNavigation("Gallery/List");
  }

  handleNavigateGalleryGridView = () => {
    this.handleNavigation("Gallery/Grid");
  }

  getBrands = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    import("../../utilities/src/BlockHelpers")
    .then((BlockHelpers) => BlockHelpers.default)
    .then((BlockHelpers) => this.getBrandsApiCallId = BlockHelpers.callApi({
        method: configJSON.getApiMethod,
        endPoint: configJSON.getBrandsEndPoint,
        header,
      })
    )
  };

  handleBrandName = (responseJson: Brand) => {
    this.setState({renameGallery : responseJson.data.attributes.header.navigation_item_3});
  };
  // Customizable Area End
}
